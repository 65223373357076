/*************************************************************************************
* disabledToggle
*************************************************************************************/
const init = function(){
	$(this).each(function(index, el) {
		change.call($(this))
	});
}

const change = function(){
	const _type = $(this)[0].type || $(this)[0].localName
	const _target = $(this).data('display-target')
	const _flag = {
		option: $(this).prop('selected')
	}[_type] || $(this).prop('checked')

	!!_flag ? $(`[data-display="${_target}"]`).removeClass('hidden') : $(`[data-display="${_target}"]`).addClass('hidden')
}

module.exports = {
	init,
	change,
}
