//jqueryをCDNで読み込むための対策
window.require = (name) => {
  if (name === "jquery") return window.jQuery;
  else throw new Error(`Cannot require ${name}`);
};
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* $$$ 外出し
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const $$$ = (window.$$$ = {});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* プラグインrequire
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
window.AjaxZip3 = require("./_plugins/_ajaxzip3.custom");

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* require utils
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.anim = require("./_utils/_anim");
// $$$.extraChange = require('./_utils/_extraChange.js')
// $$$.extraChange.init()
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* require modules
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.ua = require("./_modules/_ua");
$$$.pcsp = require("./_modules/_pcsp");
$$$.viewport = require("./_modules/_viewport");
$$$.windowInfo = require("./_modules/_windowInfo");
$$$.resizeend = require("./_modules/_resizeend");
$$$.smoothScroll = require("./_modules/_smoothScroll");
$$$.anchorJump = require("./_modules/_anchorJump");
$$$.overlay = require("./_modules/_overlay");
$$$.gNav = require("./_modules/_gNav");
// $$$.pasword = require("./_modules/_password");
$$$.contentsModal = require("./_modules/_contentsModal");
$$$.dialog = require('./_modules/_dialog');
// $$$.disabledToggle = require('./_modules/_disabledToggle');
$$$.displayToggle = require('./_modules/_displayToggle');
$$$.fileForm = require('./_modules/_fileForm');
$$$.acc = require('./_modules/_acc');
// $$$.tab = require('./_modules/_tab');
// $$$.tree = require('./_modules/_tree');
// $$$.ajaxMore = require('./_modules/_ajaxMore');
// $$$.objFitPolyfill = require('./_modules/_objFitPolyfill');
// $$$.share = require('./_modules/_share');

// const Validator = require('./_modules/_validator/_validator');
// const ruleList = require('./_modules/_validator/_ruleList')
// const errMsgList = require('./_modules/_validator/_errorMsg/_ja')
// const errMsgList = require('./_modules/_validator/_errorMsg/_ja')
// const errMsgList = require('./_modules/_validator/_errorMsg/_ja')

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* もろもろ実行
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.ua.judge();
$$$.pcsp.init(736);
$$$.viewport.init(1240, 736);
$$$.windowInfo.init();
$$$.resizeend.init();
$$$.fileForm.init();
// $$$.validator = new Validator($('[data-validate]')[0], ruleList, errMsgList);

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window load
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on("load", function (event) {
  $$$.anchorJump.init({
    adjust: 20,
    spAdjust: 20,
  });
  // $$$.objFitPolyfill.init();
  $$$.acc.init.call($('[data-acc]'));
  // $$$.tab.init.call($('[data-tab]'));
  // $$$.tree.init.call($('[data-tree]'));
  // $$$.ajaxMore.init.call($('[data-ajax-more]'))
  // $$$.disabledToggle.init.call($('[data-disabled-target]'));
  // $$$.displayToggle.init.call($('[data-display-target]'));
});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window resize
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on("resize", function (event) {
  $$$.pcsp.judge();
  $$$.windowInfo.updateSize();
  $$$.windowInfo.omitScrollBarSize();
});

$(window).on("resizeend.any", function (event) {
  $$$.viewport.update();
  // $$$.objFitPolyfill.update();
});

$(window).on("resizeend.width", function (event) {});

$(window).on("resizeend.height", function (event) {});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window pcsp.changed
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on("pcsp.changed", function (event, mode) {
  $$$.viewport.update();
  // $$$.objFitPolyfill.update();
});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window scroll
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on("scroll", function (event) {
  $$$.windowInfo.updateScroll();
});

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* click event
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

//gNav
$(document).on("click", ".gNav_btn", function (event) {
  event.preventDefault();
  $$$.gNav.toggle();
});
$(document).on("click", ".gNav_closeBtn", function (event) {
  event.preventDefault();
  $$$.gNav.close();
});

//smoothScroll
$(document).on(
  "click",
  '[href*="#"]:not([data-contents-modal])',
  function (event) {
    $$$.smoothScroll.scroll.call($(this), event, {
      adjust: 20,
      spAdjust: 20,
    });
  }
);

//overlay
$(document).on("click.overlay", ".overlay", function (event) {
  event.preventDefault();
  $$$.overlay.close();
  $$$.gNav.close();
});

$(document).on("click", "[data-contents-modal]", function (event) {
  event.preventDefault();
  $$$.contentsModal.open.call($(this));
});

// fileForm ローカルファイルを選択
$(document).on('click', '.fileForm_btn > a', function(event) {
	event.preventDefault();
	$$$.fileForm.add.call($(this));
});

// fileForm 選択済みアイテム削除
// $(document).on('click', '.fileForm_del', function(event) {
// 	event.preventDefault();
// 	$$$.fileForm.remove.call($(this));
// });

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* other event
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
//EnterでformのSubmit防止
$(document).on("keydown", "[data-no-submit-on-enter] input", function (event) {
  if (
    (event.which && event.which === 13) ||
    (event.keyCode && event.keyCode === 13)
  ) {
    event.preventDefault();
    return false;
  }
});

//disabledToggle
// $(document).on('extraChange', '[data-disabled-target]', function(event) {
// 	event.preventDefault();
// 	$$$.disabledToggle.change.call($(this))
// });

//displayToggle
// $(document).on('extraChange', '[data-display-target]', function(event) {
// 	event.preventDefault();
// 	$$$.displayToggle.change.call($(this))
// });

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* plugins
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

$(".gNavSp_link-qr").click(async function(event) {
  event.preventDefault();
  $$$.gNav.close();
  const video = document.createElement("video");
  const container = $("#container");
  const userMediaAndroid = { video: { facingMode: { exact: "environment" } } };
  const userMediaIPhone = { video: { facingMode: "environment" } };
  let userMedia;
  let cameraStream;
  let cameraRunning = false;
  let cameraTimeout;

  if (isAndroid()) {
    userMedia = userMediaAndroid;
  } else if (isiPhone()) {
    userMedia = userMediaIPhone;
  } else {
    alert("このデバイスはサポートされていません。");
    return;
  }

  function startCamera() {
    resetCameraTimeout();
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("getUserMediaがサポートされていません。");
      return;
    }

    navigator.mediaDevices.getUserMedia(userMedia)
      .then((stream) => {
        cameraStream = stream;
        video.srcObject = stream;
        video.setAttribute("playsinline", true);
        video.setAttribute("muted", true);
        return video.play();
      })
      .then(() => {
        video.addEventListener("loadedmetadata", handleMetadata);
        container.append(video).css("display", "block");
        $$$.overlay.open();
        startTick();
      })
      .catch((error) => {
        alert("カメラのアクセスが拒否されました:", error);
        stopCamera();
      });
  }

  function handleMetadata() {
    video.width = window.innerWidth;
    video.height = window.innerHeight;
    resetCameraTimeout();
  }

  function stopCamera() {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      cameraStream = null;
    }
    cameraRunning = false;
  }

  function startTick() {
    if (!cameraRunning) {
      cameraRunning = true;
      tick();
    }
  }

  function tick() {
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, canvas.width, canvas.height, { inversionAttempts: "dontInvert" });
      if (code && code.data.startsWith("http")) {
        const { location } = code;
        ctx.lineWidth = 4;
        ctx.strokeStyle = "#FF3B58";
        ctx.beginPath();
        ctx.moveTo(location.topLeftCorner.x, location.topLeftCorner.y);
        ctx.lineTo(location.topRightCorner.x, location.topRightCorner.y);
        ctx.lineTo(location.bottomRightCorner.x, location.bottomRightCorner.y);
        ctx.lineTo(location.bottomLeftCorner.x, location.bottomLeftCorner.y);
        ctx.closePath();
        ctx.stroke();
        container.append(canvas);
        handleQRCode(code.data);
      }
    }
    if (cameraRunning) {
      requestAnimationFrame(tick);
    }
  }

  function handleQRCode(data) {
    stopCamera();
    if (data) {
      const a = document.createElement('a');
      a.href = data;
      a.target = '_self'; // 現在のタブで開く
      a.rel = 'noreferrer'; // リファラを削除
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // リンクを削除
    }
  }

  // function handleQRCode(data) {
  //   stopCamera();
  //   if (data) {
  //     window.location.replace(data); // replace で リファラ削除
  //   }
  // }

  // function handleQRCode(data) {
  //   stopCamera();
  //   if (data) {
  //     window.location.href = data;
  //   }
  // }

  function startCameraOnClick() {
    $(document.body).off("click", startCameraOnClick);
    stopCamera();
    setTimeout(() => {
      startCamera();
      resetCameraTimeout();
    }, 500);
  }

  $(document.body).on("click", startCameraOnClick);

  function resetCameraTimeout() {
    clearTimeout(cameraTimeout);
    cameraTimeout = setTimeout(stopCamera, 60000);
  }

  function isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  function isiPhone() {
    return /iPhone/i.test(navigator.userAgent);
  }
});