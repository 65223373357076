const overlay = require('./_overlay')
/*************************************************************************************
* moduleName
*************************************************************************************/
const open = function() {
    overlay.open()
    $('.gNavSp').addClass('gNavSp-active');
    $('.gNav_btn').addClass('gNav_btn-active');
    $(".gNav_spIcon p").text("閉じる");
}

const close = function() {
    overlay.close()
    $('.gNavSp').removeClass('gNavSp-active');
    $('.gNav_btn').removeClass('gNav_btn-active');
    $(".gNav_spIcon p").text("MENU");
}

const toggle = function(){
	$('.gNavSp').hasClass('gNavSp-active') ? close() : open();
	// $('.gNav_btn').hasClass('gNav_btn-active') ? close() : open();
}


module.exports = {
    open,
    close,
    toggle,
}
